// footer
.footer {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		text-shadow: 2px 2px 2px #000f9f4f;
	}

	margin-top: 6vh;

	// footer-partners
	.footer-partners {
		padding: 6vh 0;

		&:first-of-type {
			padding-top: 0;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-outro
	.footer-outro {
		position: relative;
		padding: 45px 0 1px 0;
		background: url("/images/footer-curve.svg") no-repeat top center $blue;
		color: $white;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/footer-curve.png");
			aspect-ratio: 1366 / 299;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 100px;
		}

		.container:not(:first-of-type) {
			margin-top: 6vh;
			margin-bottom: 6vh;
		}

		.container:not(:first-of-type):not(:last-of-type) {
			.container-holder {
				@include media-breakpoint-up(md) {
					max-width: 710px;
				}
			}
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		a:not(.btn) {
			color: $white;

			&:hover {
				color: $green;
			}
		}

		// footer-mascotte
		.footer-mascotte {
			@include media-breakpoint-down(sm) {
				margin: 0 auto;
				margin-top: calc(-145px - 6vh);
			}

			@include media-breakpoint-up(md) {
				z-index: 100;
				position: absolute;
				top: -6vh;
				right: 0;
			}

			img {
				width: 100%;
				min-width: 100%;
				max-width: 370px;

				@include media-breakpoint-down(lg) {
					max-width: 275px;
				}
			}
		}

		// footer-text
		.footer-text {
			@include media-breakpoint-down(xs) {
				text-align: center;
			}

			a:not(.btn) {
				font-size: 18px;
				text-decoration: none;
				font-family: $font-family-secondary;
			}
		}

		// footer-link
		.footer-link {
			margin: 5px 0 15px 0;

			ul {
				@include media-breakpoint-down(xs) {
					justify-content: center;
				}

				li {
					margin: 0 10px 10px 0;

					a {
						@extend .btn;
						display: inline-flex;
						align-items: center;
						background-color: $blue-dark;
						border-color: $blue-dark;
						color: $white;
						font-size: 18px;

						&::before {
							margin-right: 10px;
							font-size: 20px;
							font-weight: 900;
							font-family: $font-awesome;
						}

						&[href^="tel:"] {
							&::before {
								content: "\f095";
							}
						}

						&[href^="mailto:"] {
							&::before {
								content: "\f0e0";
							}
						}

						&:hover {
							background-color: $red;
							border-color: $red;
							color: $white;
						}
					}
				}
			}
		}

		// footer-faq
		.footer-faq {
			.faq-overview {
				.faq-category {
					margin-top: 0;

					.faq-items {
						.faq-item {
							&:not(:last-of-type) {
								border-bottom: 1px solid $white;
							}

							.faq-question {
								display: flex;
								align-items: center;
								margin: 0;
								padding: 15px 0;
								border: none;

								.faq-category {
									margin: 0;

									h3 {
										color: $white;
										font-size: 18px;
										transition: 0.5s;
									}
								}

								.faq-icon {
									top: auto;
									left: auto;
									right: 0;
									width: auto;
									color: $white;
									font-size: 24px;
									line-height: normal;

									i {
										&::before {
											content: "\f0d7";
										}
									}
								}

								&:hover {

									h3,
									.faq-icon {
										color: $green;
									}
								}
							}

							.faq-answer {
								margin: 0;
								padding: 0;
								border: none;
								color: $white;
							}
						}
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				@include media-breakpoint-down(xs) {
					justify-content: center;
				}

				li {
					+li {
						margin-left: 25px;
					}

					font-size: 24px;

					a {
						display: block;
					}
				}
			}
		}

		// footer-logo
		.footer-logo {
			max-width: 260px;

			@include media-breakpoint-down(md) {
				margin: 0 auto;
				max-width: 260px;
			}

			@include media-breakpoint-up(md) {
				position: absolute;
				right: 0;
				bottom: 0;
			}

			@include media-breakpoint-up(lg) {
				max-width: 360px;
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			li {
				margin: 0 15px;
				font-size: 14px;

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
