// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	// min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 45vh;
	// min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}

	@include media-breakpoint-up(xl) {
		height: calc(100vh - 123px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	&::after {
		content: url("/images/eyecatcher-curve.svg");
		z-index: 90;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/eyecatcher-curve.svg");
			background-size: cover;
			aspect-ratio: 1366 / 323;
		}
	}

	// owl-carousel
	.owl-carousel {
		// owl-nav/dots
		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	// bobbie-animation
	.bobbie-animation {
		@media (max-width: 1640px) {
			display: none;
		}

		z-index: 100;
		position: absolute;
		right: 93%;
		bottom: -50px;

		img {
			width: 21vw;
			max-width: 350px;
		}
	}

	// bobbie-smile
	.bobbie-smile {
		@media (max-width: 1399px) {
			display: none;
		}

		z-index: 100;
		position: absolute;
		right: 90%;
		bottom: 67px;

		img {
			width: 10vw;
			max-width: 171px;
		}
	}
}

&.landing-page {
	.eyecatcher {
		.bobbie-smile {
			display: none !important;
		}
	}
}
