// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	background-color: $white;
	border: none;
	border-radius: 10px;
	box-shadow: 0 0 15px rgba($black, 0.15);
	transition: box-shadow 0.5s;

	.card-image {
		&::after {
			content: url("/images/card-curve-green.svg");
			z-index: 1;
			position: absolute;
			left: -2.5px;
			right: -2.5px;
			bottom: -2.5px;
			font-size: 0;
			line-height: 0;
		}

		.card-image-caption {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 20px 20px 75px 20px;
			background: $purple;
			color: $white;
			transition: 0.5s;
			opacity: 0;

			a {
				color: $white;

				&:hover {
					color: $black;
				}
			}

			.custom-list li::before {
				color: $white;
			}
		}
	}

	.card-body {
		padding: 20px;

		.card-caption {
			color: $gray-dark;

			.card-title-link {
				margin-bottom: 0.375rem;
				color: $blue;

				&:hover {
					.card-title {
						color: $purple;
					}
				}
			}

			.card-title {
				color: $blue;
			}

			.card-subtitle {
				z-index: 2;
				font-size: 16px;
				font-weight: 700;
				font-family: $font-family-primary;
			}
		}
	}

	&:hover {
		box-shadow: 0 0 30px rgba($black, 0.25);

		.card-image {
			.card-image-caption {
				opacity: 1;
			}
		}
	}
}

// link-arrow
.link-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	color: $black;
	font-weight: 600;
	padding-right: 20px;

	&::after {
		content: "\f061";
		position: absolute;
		right: 0;
		color: inherit;
		font-size: 14px;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		border-bottom-color: $red;
		color: $red;
		transform: translateX(5px);
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 10px;
			box-shadow: 0 0 15px rgba($black, 0.15);
		}
	}
}

// timeslot-overview
&.timeslot-overview {
	.timeslot-overview-row {
		.timeslot-item-holder {
			.timeslot-item {
				.timeslot-item-days {
					.timeslot-item-day {
						.timeslot-item-day-title-holder,
						.timeslot-item-day-times-holder {
							@include make-col(6);
						}
					}
				}
			}
		}
	}
}

// owl-carousel
.owl-carousel {
 	&.slider {
		 @include media-breakpoint-down(xs) {
			.owl-nav {
				.owl-prev {
					left: -15px;
				}
				.owl-next {
					right: -15px;
				}
			}
		}
	}
}
