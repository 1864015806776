// Inter
@font-face{
    font-family: "Inter";
    src: url("/fonts/Inter.ttf");
    src: url("/fonts/Inter.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

// Alkatra
@font-face{
    font-family: "Alkatra";
    src: url("/fonts/Alkatra.ttf");
    src: url("/fonts/Alkatra.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}