// mini-sab
.mini-sab {
	.container {
		position: relative;
		display: flex;
		justify-content: center;
		// max-width: none;

		.container-holder {
			flex: 1;
			z-index: 200;
			position: relative;
			margin: 0 15px;
			padding: 0;
			border-radius: 0 10px 10px 10px;
			background: $white-alt;
			box-shadow: 0 0 20px rgba($black, 0.15);
			color: $white;

			@include media-breakpoint-down(md) {
				margin: -100px 0 0 0;
			}

			@include media-breakpoint-up(lg) {
				// position: absolute;
				// left: auto;
				// right: auto;
				margin-bottom: -60px;
			}

			// heading
			.heading {
				position: absolute;
				bottom: 130%;
				text-align: center;
				left: 0;
				margin-bottom: 60px;
				font-weight: 400;
				line-height: 1;
				font-family: $font-family-secondary;
				text-shadow: rgba($black, 1) 0.1em 0.1em 0.2em;
				@include font-size($h1-font-size);

				@include media-breakpoint-up(xl) {
					font-size: 60px;
					bottom: 100%;
					text-align: left;
				}
			}

			// tabs
			div.tabs {
				position: absolute;
				top: -44px;
				left: 0;

				ul {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;

					li {
						margin-right: 5px;

						@include media-breakpoint-down(md) {
							margin-right: 2.5px;
						}

						a {
							display: flex;
							align-items: center;
							height: 44px;
							padding: 0 20px;
							background-color: $green;
							border-radius: 10px 10px 0 0;
							color: $black;
							font-size: 14px;
							font-weight: 600;
							line-height: 44px;
							text-decoration: none;

							&:hover {
								background-color: $white-alt;
								color: $red;
							}

							i {
								font-size: 16px;
							}

							.list-item-title {
								margin-left: 5px;
							}

							@include media-breakpoint-down(sm) {
								flex-direction: column;
								padding-top: 5px;
								font-size: 10px;
								line-height: normal;

								i {
									margin-bottom: 5px;
								}
							}
						}

						&:not(:first-of-type) {
							a {
								box-shadow: inset 0 -2px 3px rgba($black, 0.15);
							}
						}

						&:first-of-type {
							a {
								background-color: $white-alt;
								color: $red;

								@include media-breakpoint-up(md) {
									padding: 0 30px;
								}
							}
						}
					}
				}
			}
		}

		// mini-search-book
		.mini-search-book {
			padding: 15px 0;

			@include media-breakpoint-up(sm) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

&.home {
	.mini-sab {
		.container {
			.container-holder {
				@include media-breakpoint-up(lg) {
					margin-top: -220px;
					margin-bottom: auto;
				}
			}
		}
	}
}
