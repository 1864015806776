// header
.header {
	z-index: 998;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: $white-alt;
	box-shadow: $shadow;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		position: sticky;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				position: unset;
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// header-top
	.header-top {
		padding: 10px 0;

		.container-holder {
			>.column {
				justify-content: flex-end;
			}
		}

		// menu-toggle
		.menu-toggle {
			position: fixed;
			top: 10px;
			left: 10px;

			.navbar-toggler {
				width: 48px;
				height: 48px;
				padding: 0;
				border: none;
				background: $green;
				border-radius: 100%;
				color: $black;
				font-size: 20px;
				transition: 0.5s;

				i {
					line-height: 48px;
					transition: transform 0.5s;
				}

				&[aria-expanded="true"] {
					i {
						transform: rotate(180deg);

						&::before {
							content: "\f00d";
						}
					}
				}

				&:hover {
					background: $blue-dark;
					color: $white;
				}
			}
		}

		// quick-contact
		div.quick-contact {
			margin-right: 15px;

			@include media-breakpoint-down(md) {
				display: none;
			}

			ul {
				display: flex;
				align-items: center;

				li {
					font-size: 14px;

					+li::before {
						content: "";
						margin: 0 15px;
						border-left: 1px solid $black;
					}

					a {
						color: $black;
						text-decoration: none;

						&:hover {
							color: $red;
						}

						i {
							margin-right: 5px;
						}
					}
				}
			}
		}

		// site-switcher
		.site-switcher {
			margin-left: 15px;

			.navbar-nav {
				&.language {
					.nav-item {
						.nav-link {
							display: flex;
							align-items: center;
							padding: 4px 10px 4px 5px !important;
							background-color: $green;
							border-color: $green;
							border-radius: 15px;
							color: $black;

							img {
								width: 20px;
								height: 20px;
							}

							&::after {
								content: "\f078";
								margin-left: 10px;
								border: none;
								height: 20px;
								font-size: 14px;
								font-weight: 400;
								font-family: $font-awesome;
							}
						}
					}
				}
			}
		}

		// book-button
		div.book-button {
			@include media-breakpoint-up(xl) {
				position: absolute;
				bottom: 20px;
			}

			ul {
				li {
					a {
						@extend .btn;
						@extend .btn-primary;

						@include media-breakpoint-down(lg) {
							padding: 2px 15px !important;
							font-size: 12px !important;
						}
					}
				}
			}
		}
	}

	// header-bottom
	.header-bottom {
		padding: 15px 0;

		// logo
		.logo {
			width: 220px;

			@include media-breakpoint-down(lg) {
				margin: 0 auto;
			}

			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 10px;
				width: 250px;
			}

			a {
				display: block;

				img {
					width: 100%;
				}
			}
		}

		// menu
		.menu {
			@include media-breakpoint-down(lg) {
				display: none !important;
			}

			@include media-breakpoint-up(xl) {
				justify-content: center;
				border: none;
				margin-left: 250px;
				margin-right: 150px;

				.navbar-nav {
					>.nav-item {
						margin: 0 10px;
						font-size: 18px;
						font-weight: 600;
						font-family: $font-family-secondary;

						>.nav-link {
							display: flex;
							align-items: center;
							color: $blue;
							text-align: center;

							&:hover {
								color: $red;
							}

							&.dropdown-toggle {
								&::after {
									content: "\f078";
									border: none;
									margin-left: 5px;
									font-size: 14px;
									font-weight: 400;
									font-family: $font-awesome;
								}
							}
						}

						&.active {
							>.nav-link {
								color: $red;
							}
						}
					}

					.nav-item {
						.dropdown-menu {
							padding: 0.5rem;

							.nav-item {
								font-weight: 400;
								line-height: 1.2;
								white-space: nowrap;

								.nav-link {
									color: $black;

									&:hover {
										color: $red;
									}
								}

								&.active {
									.nav-link {
										color: $red;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// header-mobile
	.header-mobile {
		display: none;
		background: $white-alt;
		border-radius: 0 0 10px 10px;
		overflow: hidden;

		.top {
			padding: 30px 30px 25vw 30px;
			background: url("/images/header-mobile-curve.png") no-repeat center bottom;
			background-size: contain;

			.menu {
				display: block !important;

				.navbar-nav {
					>.nav-item {
						font-size: 24px;
						font-weight: 600;
						font-family: $font-family-secondary;

						>.nav-link {
							display: flex;
							align-items: center;
							padding: 5px 0;
							color: $blue;

							&:hover {
								color: $red;
							}

							&.dropdown-toggle {
								&::after {
									content: "\f078";
									border: none;
									margin-left: 7px;
									font-size: 18px;
									font-weight: 400;
									font-family: $font-awesome;
								}
							}
						}

						&.active {
							>.nav-link {
								color: $red;
							}
						}
					}

					.nav-item {
						.dropdown-menu {
							position: static !important;
							transform: none !important;
							margin: 0 0 0 15px;
							padding: 0;
							border: none;
							box-shadow: none;
							background: transparent;

							.nav-item {
								font-size: 14px;
								font-weight: 400;
								font-family: $font-family-primary;

								.nav-link {
									display: flex;
									align-items: center;
									padding-left: 25px;
									color: $black;

									&::before {
										content: "\f061";
										position: absolute;
										left: 0;
										font-weight: 300;
										font-family: $font-awesome;
									}

									&:hover {
										color: $red;
									}
								}

								&.active {
									>.nav-link {
										color: $red;
									}
								}
							}
						}
					}
				}
			}
		}

		.bottom {
			margin-top: -2px;
			padding: 0 30px 40px 30px;
			background: $blue;

			// quick-contact
			div.quick-contact {
				ul {
					flex-direction: column;
					margin: -2px 0;

					li {
						margin: 2px 0;
						font-size: 14px;

						a {
							color: $white;
							text-decoration: none;

							&:hover {
								color: $green;
							}

							i {
								margin-right: 5px;
							}
						}
					}
				}
			}

			// quick-icons
			div.quick-icons {
				position: absolute;
				right: 30px;
				bottom: 30px;

				ul {
					display: flex;
					align-items: center;

					li {
						font-size: 20px;

						+li {
							margin-left: 15px;
						}

						a {
							color: $white;
							text-decoration: none;

							.list-item-title {
								display: none;
							}

							&:hover {
								color: $green;
							}
						}
					}
				}
			}
		}
	}
}

&.faq-overview,
&.accommodation-search-book,
&.accommodation-book {
	.header {
		// position: relative !important;
	}
}

&.navbar-collapse-active {
	&::before {
		content: "";
		z-index: 995;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($black, 0.5);
	}

	.header {
		position: fixed;
		background: none;
		z-index: 1000;

		.header-top {
			background: $white-alt;
		}

		.header-bottom {
			display: none;
		}

		.header-mobile {
			display: block;
		}
	}
}
