// bg-*
// =========================================================================
section {

	&.bg-light {
		margin: 0;
		padding: 6vh 0;
	}

	&.bg-dark {
		position: relative;
		margin: 6vh 0;
		padding: 0;

		&::before {
			content: url("/images/curve-top-green.svg");
			display: block;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
			background: $white-alt;

			@include media-breakpoint-down(md) {
				content: "";
				background-image: url("/images/curve-top-green.png");
				background-size: cover;
				aspect-ratio: 683 / 39;
			}
		}

		&::after {
			content: url("/images/curve-bottom-green.svg");
			display: block;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
			background: $white-alt;

			@include media-breakpoint-down(md) {
				content: "";
				background-image: url("/images/curve-bottom-green.png");
				background-size: cover;
				aspect-ratio: 683 / 29;
			}
		}

		// a
		a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {
			color: $blue-dark;

			&:hover {
				color: $red;
			}
		}

		// wysiwyg
		.wysiwyg {

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $white;
				text-shadow: 2px 2px 2px #5d800c4d;
			}
		}

		// card
		.card {
			.card-image {
				&::after {
					content: url("/images/card-curve-purple.svg") !important;
				}
			}
		}
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section,
.bundle-detail-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home-section
// =========================================================================
.home-section {
	position: relative;
	margin: 30px 0 6vh 0;

	.bottom {
		margin-top: 45px;

		.container-holder {
			>.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(5);
						padding-right: 15px !important;
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
						padding-left: 15px !important;
					}
				}
			}
		}
	}

	// visual-mascotte
	.visual-mascotte {
		display: none; // hide
		z-index: 100;
		position: relative;

		@include media-breakpoint-down(sm) {
			max-width: 275px;
			margin: 0 auto;
		}

		@include media-breakpoint-up(lg) {
			margin-top: -175px;
		}

		img {
			width: 100%;
			min-width: 100%;
			max-width: 370px;
		}
	}

	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-down(sm) {
						@include make-col(6);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(3);
					}
				}
			}
		}

		.card {
			border: none;
			background: none;
			overflow: visible;

			.card-image {
				border-radius: 100%;
				box-shadow: 0 0 20px rgba($black, 0.15);
				overflow: visible;

				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: -1px;
					bottom: -1px;
					border-radius: 100%;
					box-shadow: inset -4px -4px 0 4px $green;
					transition: 0.5s;
				}

				.card-img {
					border-radius: 100%;
				}

				&:hover {
					.card-img {
						transform: scale(1);
					}
				}
			}

			.card-body {
				padding: 15px 0 0 0;

				.card-caption {
					text-align: center;

					.card-title {
						color: $red;
						font-size: 18px;
						font-weight: 400;
					}

					.card-subtitle,
					.card-description {
						display: none;
					}
				}
			}

			&:hover {
				.card-image {
					&::after {
						box-shadow: inset -4px -4px 0 4px $purple;
					}
				}

				.card-body {
					.card-caption {
						.card-title {
							color: $purple;
						}
					}
				}
			}
		}
	}

	.wysiwyg {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		ul {
			li {
				margin-bottom: 5px;
				padding-left: 35px;
				color: $blue;
				@include font-size($h4-font-size);
				font-family: $font-family-secondary;

				@include media-breakpoint-down(sm) {
					padding-left: 0;
				}

				&::before {
					color: $green;
					font-size: 24px;

					@include media-breakpoint-down(sm) {
						position: static;
						margin-right: 10px;
					}
				}
			}
		}
	}
}

// featured-collection-section
// =========================================================================
.featured-collection-section {
	position: relative;
	margin: 6vh 0;
	background: $green;
	color: $green-darker;

	&::before {
		content: url("/images/curve-top-green.svg");
		display: block;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background: $white-alt;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/curve-top-green.png");
			background-size: cover;
			aspect-ratio: 683 / 39;
		}
	}

	&::after {
		content: url("/images/curve-bottom-green.svg");
		display: block;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background: $white-alt;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/curve-bottom-green.png");
			background-size: cover;
			aspect-ratio: 683 / 29;
		}
	}

	// wysiwyg
	.wysiwyg {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			text-shadow: 2px 2px 2px #5d800c4d;
		}
	}

	// collection
	.collection {
		.card {
			@extend .default-card;

			.card-image {
				&::after {
					content: url("/images/card-curve-purple.svg");
				}
			}
		}
	}
}

// offcanvas-collection-section
// =========================================================================
.offcanvas-collection-section {
	margin: 6vh 0;
	overflow: hidden;

	// collection
	.collection {
		.owl-stage-outer {
			padding: 30px 0;
		}

		.card {
			@extend .default-card;

			.card-body {
				position: relative;
				padding: 30px 20px;

				.card-caption {
					.card-title-link {
						margin-bottom: 0;
					}

					.card-subtitle {
						position: absolute;
						right: 20px;
						bottom: calc(100% - 20px);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 120px;
						height: 120px;
						margin: 0;
						padding: 5px;
						background-color: $blue;
						border-radius: 100%;
						color: $white;
						font-size: 14px;
						font-weight: 700;
						text-align: center;
						overflow: hidden;
					}

					.card-description {
						display: flex;
						flex-direction: column;

						ul {
							order: -1;
							margin: 0.375rem 0 1.5rem 0;

							li {
								display: flex;
								align-items: center;
								padding-left: 25px;
								color: $green-dark;

								.label {
									font-size: 12px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}

		.owl-nav {
			.owl-prev {
				left: 15px;
			}

			.owl-next {
				right: 15px;
			}
		}
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 10px;

			&::before {
				content: "";
				z-index: 1;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(0deg,
						rgba($black, 0.5) 0%,
						rgba($black, 0) 100%);
			}

			.card-img-overlay {
				z-index: 2;
				display: flex;
				flex-direction: column;
				padding: 20px;
				background: transparent;
				transition: 0.5s;

				@include media-breakpoint-up(lg) {
					padding: 25px;
				}

				.card-caption {
					display: flex;
					flex-direction: column;
					flex: 0;
					margin-top: auto;

					.card-title {
						order: 4;
						margin-bottom: 0;
						text-shadow: none;

						&:hover {
							color: $white;
						}
					}

					.card-subtitle,
					.card-description {
						height: 0;
						margin: 0;
						text-shadow: none;
						overflow: hidden;
						transition: 0.5s;
					}
				}

				.card-buttons {
					display: none;

					@include media-breakpoint-up(md) {
						height: 0;
						margin: 0;
						overflow: hidden;
						transition: 0.5s;
					}

					.card-btn {
						@extend .link-arrow;
						padding: 0 20px 0 0;
						border: none;
						background: transparent;
						color: $white;
						text-decoration: underline;
					}
				}
			}

			&:hover {
				box-shadow: 0 0 30px rgba($black, 0.25);

				.card-img-overlay {
					background: $blue;

					.card-caption {

						.card-subtitle,
						.card-description {
							height: auto;
						}

						.card-description {
							margin-bottom: 30px;
						}
					}

					.card-buttons {
						height: auto;
					}
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 0 0 6vh 0;

	@include media-breakpoint-down(md) {
		margin-top: 3vh;
	}

	.column.two {
		@include media-breakpoint-up(md) {
			padding-top: 45px;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	.resource-image+.wysiwyg {
		margin-top: 1rem;
	}

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

&.accommodation-book {
	.content-section {
		position: relative;
		background: $green;

		&::before {
			content: url("/images/curve-top-green.svg");
			display: block;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
			background: $white-alt;

			@include media-breakpoint-down(md) {
				content: "";
				background-image: url("/images/curve-top-green.png");
				background-size: cover;
				aspect-ratio: 683 / 39;
			}
		}

		&::after {
			content: url("/images/curve-bottom-green.svg");
			display: block;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
			background: $white-alt;

			@include media-breakpoint-down(md) {
				content: "";
				background-image: url("/images/curve-bottom-green.png");
				background-size: cover;
				aspect-ratio: 683 / 29;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// news-collection-section
// =========================================================================
.news-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		margin: 0 0 15px 0;

		&.grid {
			.grid-items {
				margin-bottom: 0;

				.item {
					@include make-col(12);
					margin-bottom: 0 !important;

					.card {
						border: none;
						border-radius: 0;
						background: none;

						.card-image {
							display: none;
						}

						.card-body {
							padding: 15px 10px;

							.card-caption {
								position: relative;
								display: flex;
								flex-direction: column;

								@include media-breakpoint-up(sm) {
									padding-left: 200px;
								}

								.card-title-link {
									display: inline-flex;
									align-items: center;
									margin-bottom: 0.375rem;
									color: $blue;

									.card-title {
										font-size: 16px;
										font-weight: 600;
										font-family: $font-family-primary;

										&::after {
											content: "\f061";
											margin-left: 7px;
											color: inherit;
											font-size: 14px;
											font-weight: 900;
											font-family: $font-awesome;
										}
									}

									&:hover {
										.card-title {
											color: $purple;
											transform: translateX(5px);
										}
									}
								}

								.card-subtitle {
									@include media-breakpoint-up(sm) {
										position: absolute;
										top: 0;
										left: 0;
									}

									order: -1;
									margin-top: 0;
									font-size: 16px;
									font-weight: 600;
									font-family: $font-family-primary;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}

					&:nth-child(even) {
						.card {
							border-top: 1px solid $black;
							border-bottom: 1px solid $black;
							background: #f2f4ca;
						}
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;

			.icon i,
			.label {
				color: $green-dark;
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 0 0 6vh 0;

	.column.two {
		@include media-breakpoint-up(md) {
			padding-top: 45px;
		}

		.image-collection+.embed-responsive {
			margin-top: 30px;
		}

		.embed-responsive {
			border-radius: 10px;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
		}

	}
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	position: relative;
	margin: 6vh 0;
	background: $green;

	&::before {
		content: url("/images/curve-top-green.svg");
		display: block;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background: $white-alt;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/curve-top-green.png");
			background-size: cover;
			aspect-ratio: 683 / 39;
		}
	}

	&::after {
		content: url("/images/curve-bottom-green.svg");
		display: block;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background: $white-alt;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/curve-bottom-green.png");
			background-size: cover;
			aspect-ratio: 683 / 29;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 30px;
		color: $white;
		text-shadow: 2px 2px 2px #5d800c4d;
	}

	.custom-list-extra {
		padding-bottom: 20px;
	}

	@include media-breakpoint-down(md) {
		>.column {
			padding: 20px;
		}
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

// block-section (landing-page)
// =========================================================================
.block-section {
	margin: 6vh 0;

	// wysiwyg
	.wysiwyg {
		margin-bottom: 20px;
	}

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// nl/dagrecreatie
&.dagrecreatie {

	.block-section {

		// collection
		.collection {
			&.slider {
				@include media-breakpoint-down(lg) {
					@include make-row();
					justify-content: center;
					margin: 0 -15px -30px -15px;

					.item {
						@include make-col-ready();
						@include make-col(12);

						margin: 0 0 30px 0;

						@include media-breakpoint-only(sm) {
							@include make-col(6);
						}

						@include media-breakpoint-only(md) {
							@include make-col(6);
						}

						@include media-breakpoint-only(lg) {
							@include make-col(4);
						}
					}
				}
			}
		}
	}
}
