.timeslot-datepicker {
	background: $green;
	border: 1px solid $green;
}

.timeslot-item-day-title {
	background: $green !important;
}

.timeslot-item-title {
	color: $blue !important;
}

.timeslot-item-day-times {
	background: #0194d233 !important;
}

.timeslot-item-navigation-btn {
	background: #000f9f !important;
	border: 0px !important;
	&:hover {
		background: #e1251b !important;
	}
}
